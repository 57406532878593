import { ApolloProvider } from "@apollo/client";
import client from "./../config/apollo";
import "./../../public/fonts/fonts.css";
import { useRouter } from "next/router";

import { createEmotionCache } from "../utils/emotion";
import { CacheProvider } from "@emotion/react";

const clientSideEmotionCache = createEmotionCache();

export default function App(props) {
  const { Component, emotionCache = clientSideEmotionCache, pageProps } = props;
  const getLayout = Component.getLayout || ((page) => page);

  const { asPath } = useRouter();

  return (
    <CacheProvider value={emotionCache}>
      <ApolloProvider client={client}>
        {getLayout(<Component {...pageProps} />)}
      </ApolloProvider>
    </CacheProvider>
  );
}
