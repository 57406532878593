import { Alert, Button, IconButton, Snackbar, Typography } from "@mui/material";
import React, { FC, PropsWithChildren, useState } from "react";
import CloseIcon from "@mui/icons-material/Close";
import { makeVar, useReactiveVar } from "@apollo/client";

interface Props {
  open: boolean;
  hideDuration?: number;
  severity?: "success" | "error" | "info";
  children?: JSX.Element;
}

const notificationState = makeVar<Props>({
  open: false,
  hideDuration: 3000,
  severity: "success",
  children: undefined,
});

export const setNotificationState = (
  s: Pick<Props, "children" | "severity" | "hideDuration">
) => {
  notificationState({
    open: true,
    ...s,
  });
};

const Notification = () => {
  const { open, hideDuration, severity, children } =
    useReactiveVar(notificationState);

  const closeNotification = (e: any) => {
    notificationState({
      open: false,
      hideDuration: 3000,
      severity: "success",
      children: undefined,
    });
  };

  const action = (
    <React.Fragment>
      <IconButton
        size="small"
        aria-label="close"
        color="inherit"
        onClick={closeNotification}
      >
        <CloseIcon fontSize="small" />
      </IconButton>
    </React.Fragment>
  );

  const success = (
    <Alert severity="success" action={action}>
      <Typography
        sx={{
          color: "#000",
          fontWeight: "400",
          span: {
            color: "success.main",
            fontWeight: "700",
          },
        }}
      >
        Changes were <span>saved</span> successfully
      </Typography>
    </Alert>
  );

  const error = (
    <Alert severity="error" action={action}>
      <Typography
        sx={{
          color: "#000",
          fontWeight: "400",
          span: {
            color: "error.main",
            fontWeight: "700",
          },
        }}
      >
        <span>Error occured </span>while saving your changes
      </Typography>
    </Alert>
  );

  const info = (
    <Alert severity="info" action={action}>
      <Typography
        sx={{
          color: "#000",
          fontWeight: "400",
          span: {
            color: "purple.main",
            fontWeight: "700",
          },
        }}
      >
        <span>Saving </span>changes ...
      </Typography>
    </Alert>
  );

  const base = {
    success,
    error,
    info,
  };

  return (
    <Snackbar
      sx={{
        zIndex: "10000000000",
      }}
      open={open}
      autoHideDuration={3000}
      onClose={closeNotification}
    >
      {(children as any) || base[severity || "success"]}
    </Snackbar>
  );
};

export default Notification;
