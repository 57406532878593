import { Box, Grid, Stack, Typography } from "@mui/material";
import React, { FC } from "react";
import { LinkType } from "../../../../../generated/graphql";
import Modal from "../../../../reusable/modals/Modal";

interface Props {
  parent?: string;
  onSelectEmbed: (type: LinkType, id: string) => void;
  close: any;
}

const getHostname = (url: string) => {
  const match = url.match(/:\/\/(www[0-9]?\.)?(.[^/:]+)/i);
  if (
    match &&
    match.length > 2 &&
    typeof match[2] === "string" &&
    match[2].length > 0
  ) {
    return match[2];
  }
  return "";
};

export const isEmbedable = (url: string) => {
  if (url.includes("youtube.com/c/") || url.includes("youtube.com/channel")) {
    return false;
  }
  return EMBEDABLE_SERVICES.map((s) => s.oembed)
    .flat()
    .find((u) => getHostname(url).includes(u));
};

export const getServiceId = (url: string) => {
  if (url.includes("youtube.com/c/") || url.includes("youtube.com/channel")) {
    return "";
  }
  return (
    EMBEDABLE_SERVICES.filter((s) => s.oembed.includes(getHostname(url)))[0]
      ?.id || ""
  );
};

const EMBEDABLE_SERVICES = [
  {
    id: "googleform",
    name: "Google Form",
    oembed: ["docs.google.com"],
    colors: ["#148225", "#1482251A"],
    inputPlaceholder: "Google Form URL",
  },
  {
    id: "vimeo",
    name: "Vimeo Video",
    oembed: ["vimeo.com"],
    colors: ["#32B8E8", "#32B8E81A"],
    inputPlaceholder: "Vimeo Video URL",
  },
  {
    id: "kickstarter",
    name: "Kickstarter",
    oembed: ["kickstarter.com"],
    colors: ["#05CE78", "#05CE781A"],
    inputPlaceholder: "Kickstarter URL",
  },
  {
    id: "soundcloud",
    name: "Soundcloud embed",
    oembed: ["soundcloud.com"],
    colors: ["#F47121", "#F471211A"],
    inputPlaceholder: "Soundcloud URL",
  },
  {
    id: "youtube",
    name: "Youtube video",
    oembed: ["youtu.be", "youtube.com"],
    colors: ["#F47121", "#F471211A"],
    inputPlaceholder: "Youtube URL",
  },
  {
    id: "spotify",
    name: "Spotify embed",
    oembed: ["spotify.com", "open.spotify.com"],
    colors: ["#1ED760", "#1ED7601A"],
    inputPlaceholder: "Spotify URL",
  },
  {
    id: "typeform",
    name: "Typeform",
    oembed: ["typeform.com"],
    colors: ["#022B51", "#F4EEE7"],
    inputPlaceholder: "Typeform URL",
  },
  {
    id: "paypall",
    name: "Coming soon",
    oembed: ["-"],
    colors: ["#CDCECF", "#white"],
    inputPlaceholder: "Target URL",
  },
];

export const getDefaultPlaceholder = (service: string) => {
  return (
    EMBEDABLE_SERVICES.find((s) => s.id === service)?.inputPlaceholder ||
    "Target URL (www.example.com)"
  );
};

const EmbedsMarket: FC<Props> = ({ parent, onSelectEmbed, close }) => {
  return (
    <Modal title="Add Embed" close={close}>
      <Grid container spacing={3}>
        {EMBEDABLE_SERVICES.map(({ id, name, colors }, _) => (
          <Grid item mg={3} md={4} sm={6} xs={12}>
            <Box
              onClick={() =>
                id !== "paypall" && onSelectEmbed(LinkType.Embeded, id)
              }
              sx={{
                cursor: "pointer",
                flexDirection: "column",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                width: "100%",
                backgroundColor: colors[1],
                px: "20px",
                py: 5,
                height: "140px",
                borderRadius: "18px",
                border: id === "paypall" ? "1px dashed #CDCECF " : "",
                "&:hover": {
                  border: id !== "paypall" ? "2px solid #0F75FB" : "initial",
                },
              }}
            >
              {<Box component="img" src={`/icons/embeds/${id}.svg`} />}
              <Typography
                fontWeight={600}
                sx={{
                  mt: 1,
                  lineHeight: "30px",
                  color: colors[0],
                }}
              >
                {name}
              </Typography>
            </Box>
          </Grid>
        ))}
      </Grid>
    </Modal>
  );
};

export default EmbedsMarket;
