import {
  ApolloLink,
  Operation,
  NextLink,
  RequestHandler,
} from "@apollo/client";
import { omit } from "lodash";

type OperationTypeNode = "query" | "mutation" | "subscription";

const removeTypenameFromMutation = (
  operation: Operation,
  forward: NextLink
) => {
  const definition = operation?.query?.definitions.filter(
    (def) => def.kind === "OperationDefinition"
  )?.[0];
  const mutation: OperationTypeNode = "mutation";
  if (
    definition?.kind == "OperationDefinition" &&
    definition?.operation === mutation
  ) {
    if (Array.isArray(operation.variables.data)) {
      operation.variables.data = operation.variables.data.map((e) =>
        omit(e, "__typename")
      );
    } else {
      if (operation?.variables?.data) {
        const regex =
          /,"__typename":"\w+",?|"__typename":"\w+",?|{"__typename":"\w+"}/gi;
        operation.variables.data = JSON.parse(
          JSON.stringify(operation.variables.data).replace(regex, "")
        );
      }
    }
    return forward(operation);
  }
  return forward(operation);
};

const removeTypenameFromMutationLink = new ApolloLink(
  removeTypenameFromMutation
);

export { removeTypenameFromMutationLink, removeTypenameFromMutation };
