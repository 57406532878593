import { BoxProps, SxProps, Typography } from "@mui/material";
import { Stack } from "@mui/system";
import React, { FC, PropsWithChildren } from "react";
import CloseIcon from "@mui/icons-material/Close";

interface Props {
  close?: any;
  sx?: SxProps;
  title?: string;
}

const Modal: FC<PropsWithChildren<Props>> = ({
  children,
  close,
  title,
  sx,
}) => {
  return (
    <Stack
      sx={{
        backgroundColor: "#ffffff",
        alignItems: "center",
        ...sx,
      }}
    >
      <Stack
        px={1}
        mb={3}
        width="100%"
        flexDirection="row"
        alignItems="center"
        justifyContent={["space-between", "space-between", "center"]}
      >
        <Typography
          fontSize={24}
          fontWeight={600}
          sx={{
            textAlign: {
              xs: "left",
              sm: "center",
            },
            alignSelf: {
              xs: "start",
              sm: "center",
            },
          }}
        >
          {title}
        </Typography>
        <CloseIcon
          onClick={close}
          sx={{
            display: {
              xs: "initial",
              sm: "none",
            },
          }}
        />
      </Stack>
      <Stack
        sx={{
          p: 1,
          width: "100%",
          alignItems: "center",
          overflowY: {
            xs: "auto",
            sm: "auto",
          },
          maxHeight: {
            xs: "75vh",
            sm: "initial",
          },
        }}
      >
        {children}
      </Stack>
    </Stack>
  );
};

export default Modal;
