import React from "react";
import { makeVar, useReactiveVar } from "@apollo/client";

import CloudOffIcon from "@mui/icons-material/CloudOff";
import CloudSyncIcon from "@mui/icons-material/CloudSync";
import CloudDoneIcon from "@mui/icons-material/CloudDone";
import { Box } from "@mui/material";

type TStates = keyof typeof STATES;

const STATES = {
  default: {
    icon: <CloudDoneIcon color="success" />,
    text: null,
    bgColor: "grey",
    textColor: "#1FAB35",
  },
  err: {
    icon: <CloudOffIcon color="error" />,
    text: "Something happend",
    bgColor: "#f7191926",
    textColor: "#F71919",
  },
  upload: {
    icon: <CloudSyncIcon color="primary" />,
    text: "Saving ...",
    bgColor: "grey",
    textColor: "#1FAB35",
  },
  succes: {
    icon: <CloudDoneIcon color="success" />,
    text: "Saved",
    bgColor: "#1fab3526",
    textColor: "#1FAB35",
  },
};

const indicatorState = makeVar<TStates>("default");

export const setIndicatorState = (
  s: "default" | "err" | "upload" | "succes"
) => {
  indicatorState(s);
};

const UploadIndicator = () => {
  const state = useReactiveVar(indicatorState);

  const _indicatorState = STATES[state];

  return (
    <Box mx={3} display={"flex"}>
      {_indicatorState.icon}
    </Box>
  );
};

export default UploadIndicator;
