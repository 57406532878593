import {
  ApolloClient,
  ApolloLink,
  createHttpLink,
  InMemoryCache,
  Reference,
} from "@apollo/client";
import { BatchHttpLink } from "@apollo/client/link/batch-http";
import { onError } from "@apollo/client/link/error";
import { setContext } from "@apollo/client/link/context";
import { parseCookies } from "nookies";
import { removeTypenameFromMutationLink } from "./typenameLink";
import { isUrl } from "../../utils";
import { LinkType } from "../../generated/graphql";
import { offsetLimitPagination } from "@apollo/client/utilities";
import { setIndicatorState } from "../../components/page/dashboard/nav/UploadIndicator";
import { OperationTypeNode } from "graphql";
import { setNotificationState } from "../../ui/Notification";
import { getServiceId } from "../../components/page/dashboard/link-creator/components/EmbedsMarket";

const errorLink = onError(({ graphQLErrors, networkError }) => {
  if (graphQLErrors) {
    graphQLErrors.forEach(({ message, locations, path }) => {
      if (message === "Unauthorized") {
        localStorage.clear();
        typeof window !== "undefined" &&
          window.location.replace("/auth/signin");
      }
      if (message === "Forbidden resource") {
        typeof window !== "undefined" &&
          window.location.replace("dashboard/subscription");
      }
    });
    setNotificationState({
      severity: "error",
    });
    setIndicatorState("err");
  }
});

const requestMutationIndicator = new ApolloLink((operation, forward) => {
  const definition = operation?.query?.definitions.filter(
    (def) => def.kind === "OperationDefinition"
  )?.[0];
  const mutation: any = "mutation";

  if (
    // @ts-ignore()
    definition?.name?.value === "SignIn" ||
    // @ts-ignore()
    definition?.name?.value === "SignUp"
  )
    return forward(operation);

  if (
    definition?.kind == "OperationDefinition" &&
    definition?.operation === mutation
  ) {
    setIndicatorState("upload");
  }

  return forward(operation);
});

const responseMutationIndicator = new ApolloLink((operation, forward) => {
  return forward(operation).map((response) => {
    if (
      operation.operationName === "SignIn" ||
      operation.operationName === "SignUp"
    )
      return response;

    if (
      response &&
      // @ts-ignore()
      operation["query"]?.definitions[0]?.operation === "mutation"
    ) {
      setNotificationState({
        severity: "success",
      });
      setIndicatorState("succes");
    }
    return response;
  });
});

const httpLink = createHttpLink({
  uri: process.env.NEXT_PUBLIC_GRAPHQL_API,
  credentials: "include",
  fetchOptions: {
    keepalive: true,
  },
});

const authLink = setContext((_, { headers }) => {
  // get the authentication token from local storage if it exists
  const { joytoken } = parseCookies();

  // return the headers to the context so httpLink can read them
  return {
    headers: {
      ...headers,
      authorization: joytoken ? `Bearer ${joytoken}` : "",
    },
  };
});

const links = ApolloLink.from([
  requestMutationIndicator,
  removeTypenameFromMutationLink,
  errorLink,
  responseMutationIndicator,
  authLink,
  httpLink,
]);

export function createApolloClientLight() {
  return new ApolloClient({
    ssrMode: typeof window === "undefined",
    link: ApolloLink.from([httpLink]),
    cache: new InMemoryCache(),
  });
}

export function createApolloClient() {
  return new ApolloClient({
    connectToDevTools: true,
    ssrMode: typeof window === "undefined",
    link: links,
    cache: new InMemoryCache({
      typePolicies: {
        Query: {
          fields: {
            dashboard: {
              read(_, { args, toReference }: any) {
                return toReference({
                  __typename: "CustomDashboard",
                  id: args.dashboardId,
                });
              },
            },
            instagramPosts: {
              keyArgs: false,
              merge(existing, incoming) {
                let posts: Reference[] = [];
                if (existing && existing.data) {
                  posts = posts.concat(existing.data);
                }
                if (incoming && incoming.data) {
                  posts = posts.concat(incoming.data);
                }

                return {
                  data: posts,
                  next: incoming.next,
                };
              },
            },
            getSingleLinks: {
              keyArgs: ["category", "search", "filter"],
              merge(existing, incoming) {
                let posts: Reference[] = [];

                if (existing && existing.nodes) {
                  posts = [...posts, ...existing.nodes];
                }
                if (incoming && incoming.nodes) {
                  posts = [...posts, ...incoming.nodes];
                }

                posts = posts.reduce((a: any, b: any) => {
                  const found = a.find((e: any) => e.__ref === b.__ref);
                  return found ? a : a.push(b), a;
                }, []);

                return {
                  totalCount: incoming?.totalCount || 0,
                  nodes: posts,
                };
              },
            },
          },
        },
        LinkModel: {
          fields: {
            isLinkValid: {
              read(isLinkValid = false, { readField }) {
                const title = readField("title") as string;
                const url = readField("url") as string;
                const type = readField("type") as string;
                if (
                  type === LinkType.DropdownButton ||
                  type === LinkType.Grid ||
                  type === LinkType.SocialIcon
                )
                  return true;

                return title.length > 0 && url.length > 0;
              },
            },
            clicks: {
              merge(existing, incoming) {
                if (!existing && !incoming) return 0;

                return incoming || existing;
              },
            },
            isNew: {
              read(isNew = false) {
                return isNew;
              },
            },
            urlHolder: {
              read(h = "", { readField }) {
                const url = readField("url") as string;
                const type = readField("type") as string;

                if (url) {
                  return getServiceId(url) || h;
                }

                return h;
              },
            },
          },
        },
        QrModel: {
          fields: {
            _logo: {
              read(a, { readField }) {
                if (!a && readField("logo")) {
                  return {
                    thumbnail: readField("logo"),
                    size: 1,
                    hideBg: true,
                    margin: 0.1,
                  };
                }
                return a;
              },
            },
            files: {
              read(file = []) {
                return file;
              },
            },
          },
        },
        QrSingleLinkModel: {
          fields: {
            _logo: {
              read(a, { readField }) {
                if (!a && readField("logo")) {
                  return {
                    thumbnail: readField("logo"),
                    size: 1,
                    hideBg: true,
                    margin: 0.1,
                  };
                }
                return a;
              },
            },
            files: {
              read(file = []) {
                return file;
              },
            },
          },
        },
        CustomerBillingAddress: {
          keyFields: false,
        },
        ModuleModel: {
          keyFields: false,
        },
        EyeModel: {
          keyFields: false,
        },
        EyeColorModel: {
          keyFields: false,
        },
        QrShapeModel: {
          keyFields: false,
        },
        GradientColorModel: {
          keyFields: false,
        },
        PageThemeModel: {
          keyFields: false,
        },
        PageFontModel: {
          keyFields: false,
        },
        SocialLink: {
          keyFields: false,
        },
      },
    }),
  });
}

const client = createApolloClient();

export default client;
